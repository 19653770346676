export function IcBack({ className }: { className: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8157 3.18451C17.1282 3.49693 17.1282 4.00346 16.8157 4.31588L8.88142 12.2502L16.8157 20.1845C17.1282 20.4969 17.1282 21.0035 16.8157 21.3159C16.5033 21.6283 15.9968 21.6283 15.6844 21.3159L7.18436 12.8159C6.87194 12.5035 6.87194 11.9969 7.18436 11.6845L15.6844 3.18451C15.9968 2.87209 16.5033 2.87209 16.8157 3.18451Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function IcRemoveCircleFilledS({ className }: { className: string }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16ZM6.14645 6.14645C6.34171 5.95118 6.65829 5.95118 6.85355 6.14645L9 8.29289L11.1464 6.14645C11.3417 5.95118 11.6583 5.95118 11.8536 6.14645C12.0488 6.34171 12.0488 6.65829 11.8536 6.85355L9.70711 9L11.8536 11.1464C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536C11.6583 12.0488 11.3417 12.0488 11.1464 11.8536L9 9.70711L6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536C5.95118 11.6583 5.95118 11.3417 6.14645 11.1464L8.29289 9L6.14645 6.85355C5.95118 6.65829 5.95118 6.34171 6.14645 6.14645Z"
        fill="#868B94"
      />
    </svg>
  )
}
