import {
  HotKeywordsPlaceholder,
  RecentSearchPlaceholder,
} from '@daangn/search-sdk'
import * as css from './SearchDiscover.css'

const SearchDiscoverSkeleton = () => {
  return (
    <div className={css.container}>
      <div className={css.margin}>
        <HotKeywordsPlaceholder />
      </div>
      <RecentSearchPlaceholder />
    </div>
  )
}

export default SearchDiscoverSkeleton
