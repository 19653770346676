import { useMemo } from 'react'
import * as css from './CardCar.css'
import { TagGroupEllipsis, TagGroup } from '../tag-group'
import { TagEllipsis, Tag } from '../tag'
import { LazyImage } from '../lazy-load-image'
import { usePriceWithCurrency, useDistanceWithUnit } from '../_app'
import IconChatting from './assets/IconChatting'
import IconHeart from './assets/IconHeart'
import type { CarBadgeStyleType } from './constants'
import { CAR_BADGE_STYLE_TYPE_TO_PROPS } from './constants'
import { Badge } from '../badge'

type badge = {
  style: CarBadgeStyleType
  label: string
}
interface CardCarProps {
  thumbnail?: string
  fallbackThumbnail: string
  title: string
  price: number
  driveDistance: number
  modelYear: string | number
  badges: badge[]
  regionName: string
  isPullUp: boolean
  publishedAt: string
  chatCount: number
  voteCount: number
}

const CardCar = (props: CardCarProps) => {
  const priceWithCurrency = usePriceWithCurrency(props.price * 10_000)

  const shortenModelYear = useMemo(() => {
    const slicedModelYear =
      typeof props.modelYear === 'number'
        ? props.modelYear.toString().slice(-2)
        : props.modelYear.slice(-2)
    return `${slicedModelYear}년식`
  }, [props.modelYear])

  const driveDistanceWithSplitUnit = useDistanceWithUnit(props.driveDistance)

  const a11yLabel = useMemo(() => {
    const badgesA11yLabel = props.badges.reduce(
      (acc, { label }) => acc + `${label} `,
      ''
    )
    const publishedAtA11yLabel = props.isPullUp
      ? `끌올 ${props.publishedAt}`
      : `${props.publishedAt}`
    return `
      ${props.title},
      가격${priceWithCurrency} ${shortenModelYear} ${driveDistanceWithSplitUnit} ${badgesA11yLabel}
      ${props.regionName} ${publishedAtA11yLabel}
      채팅${props.chatCount} 관심${props.voteCount}
    `
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={css.container} aria-label={a11yLabel}>
      <div className={css.imageContainer}>
        <LazyImage
          className={css.image}
          src={props.thumbnail}
          fallbackSrc={props.fallbackThumbnail || ''}
        />
      </div>
      <div className={css.contents}>
        <div className={css.header}>
          <div className={css.title}>{props.title}</div>
        </div>
        <div className={css.body}>
          <div className={css.carSpecContainer}>
            <TagGroup>
              <Tag bold large color={'gray900'}>
                {priceWithCurrency}
              </Tag>
              <Tag large color={'gray900'}>
                {shortenModelYear}
              </Tag>
              <Tag large color={'gray900'}>
                {driveDistanceWithSplitUnit}
              </Tag>
            </TagGroup>
          </div>
          {props.badges.length ? (
            <div className={css.badgeContainer}>
              {props.badges.map((badge) => {
                return (
                  <Badge
                    key={badge.label}
                    size="small"
                    shape="square"
                    {...CAR_BADGE_STYLE_TYPE_TO_PROPS[badge.style]}
                    className={css.badge}
                  >
                    {badge.label}
                  </Badge>
                )
              })}
            </div>
          ) : null}
          <div className={css.articleStatusContainer}>
            <TagGroupEllipsis>
              <TagEllipsis>{props.regionName}</TagEllipsis>
              <TagEllipsis>
                {props.isPullUp
                  ? `끌올 ${props.publishedAt}`
                  : `${props.publishedAt}`}
              </TagEllipsis>
            </TagGroupEllipsis>
          </div>
        </div>
        <div className={css.footer}>
          {props.chatCount > 0 && (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconChatting className={css.iconChatting} />
              </div>
              {props.chatCount}
            </div>
          )}
          {props.voteCount > 0 && (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconHeart className={css.iconHeart} />
              </div>
              {props.voteCount}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardCar
