import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import { PropertyApi } from '../../__codegen__/__openapi__/search-front-server/api/property-api'
import type { ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfProperties } from '../../__codegen__/__openapi__/search-front-server'

let serviceCache: ServiceXPropertyType | null = null
export type ServiceXPropertyType = ReturnType<typeof ServiceXProperty>

export function getServiceXProperty({
  app,
  user,
}: {
  app: AppType
  user: UserType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceXProperty({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
  }))
}

const ServiceXProperty = ({
  baseUrl,
  app,
  user,
}: {
  baseUrl: string
  app: AppType
  user: UserType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
      plantaeRequestIdPlugin(),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeCommonHeadersPlugin(),
    ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new PropertyApi(undefined, baseUrl, axiosInstance)

  let sharingTextByCategory: ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfProperties | null =
    null
  let sharingTextByCategoryPromise: ReturnType<
    typeof client.apiV1PropertyFleaMarketPriceFreeTextsGet
  > | null = null

  return {
    getSharingTextByCategory: async () => {
      if (sharingTextByCategory) {
        return sharingTextByCategory
      }

      if (!sharingTextByCategoryPromise) {
        sharingTextByCategoryPromise =
          client.apiV1PropertyFleaMarketPriceFreeTextsGet()
      }

      const [error, resp] = await to(sharingTextByCategoryPromise)

      if (error) {
        captureException(error)
        return null
      }

      if (!resp?.data.properties) {
        return null
      }

      sharingTextByCategory = resp.data.properties
      return sharingTextByCategory
    },
  }
}
