import 'src/packages/flea-market/CardFleaMarket.css.ts.vanilla.css?source=#H4sIAAAAAAAAA6VWTW/aQBC951dYkSo1h0V2UkjiqJdG6sehpx6rKhrvDnjC2mvtDhBS9b93vTi2AUNJKoSAXc+beW/e7DK6B6s+a4TvYOfIDyRN+YmUe3hI1laTe46j32dR5NiaOabREux7IRyiEk6CRiGNNlbMLKzFJI4v7s7+nI0GIO9zYKZy1sImAXZKWr8d9CuC5Rbx8j8RPSADlWhbxKuAqMhVGtZpNNX4dOcXAtbxHLeHqi5ghvd7iT6ERCtSnKfRZHQ9tljUmXKkWc5bS5mxCn32pHqKnNGkjtYBuspBjOtifCjI+cyaRanEAAUsoGSSTXQFFVrRRewCOHr2bpBmiXZnpzKOmEyZRuP4Xf2u9wuwMyqF3dBJGi519FSbVRrlpBSW9VoXblED0xI71sKCooVLo3h01QpSd8Wv+Bcs2LxK9MEH2/3x6U0x2SNKFlPiviZ7NW94Hyiwy/vTWfnx/PzXtvtKU+JQcOFZtbGTYcduNEpevgpF1tcbVPbtXhRHlC982xoN4qH0TKy72q83M+i1bhwy5DBeV8a7s8rXIjNqLYpEWJwtNFjRRga/hV+rRu63IG1iA5b2vRf527B6sRssZPaddRVIf6S9Hm4r/KI3H5lhNkXtlXhy+WKutpueTjYn9k+Fpr78DNVJDUWVRpf1xpIcZaSJfUz4rrH/vA8XxhKWtRBofbGg633GJxbdSKLWVDlyhwZ15e0tMoswT6Pw4Q8bPeQQx8CL7j65GbZoowCbqqafNOwPoX2BqgW8DYDbJ0w8OggwtYg/crD9ywiGawJNs1IQY+HnV3rBNoPds3c8uukOor5bM6PVUPbKkuzGJdsdl1fjSajqme3klcNUHheOaboW9SUXOh/OASzVrvaHDtEmUZtHhTwnXIXXcXyxr1qyb+9/qL7b4KsOYmu2k7i+ck4gkGpwLGROWg056AjGN9nDwRMF75gcINicssmoI9Zy6q3tGT25bXZOaEbz3+cv1ChykPUJAAA=';
export var caption = 'CardFleaMarket_caption__1yrliszd';
export var captionIcon = 'CardFleaMarket_captionIcon__1yrlisze';
export var captions = 'CardFleaMarket_captions__1yrliszc';
export var container = 'CardFleaMarket_container__1yrlisz3';
export var freeSharing = 'CardFleaMarket_freeSharing__1yrlisza';
export var iconBids = 'CardFleaMarket_iconBids__1yrlisz0';
export var iconChatting = 'CardFleaMarket_iconChatting__1yrlisz1';
export var iconHeart = 'CardFleaMarket_iconHeart__1yrlisz2';
export var image = 'CardFleaMarket_image__1yrlisz5';
export var imageContainer = 'CardFleaMarket_imageContainer__1yrlisz4';
export var main = 'CardFleaMarket_main__1yrlisz6';
export var price = 'CardFleaMarket_price__1yrliszb';
export var status = 'CardFleaMarket_status__1yrlisz8';
export var statusGap = 'CardFleaMarket_statusGap__1yrlisz9';
export var title = 'CardFleaMarket_title__1yrlisz7';