import { memo } from 'react'

const IconReply = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 8.17605C16.5 4.43372 13.077 1.5 9 1.5C4.92169 1.5 1.5 4.44654 1.5 8.18772C1.5 11.8985 4.86547 14.8142 8.89696 14.8631C10.7776 15.66 11.727 16.0623 12.2074 16.2638C12.4492 16.3652 12.5787 16.4185 12.6491 16.4457C12.676 16.4561 12.7222 16.4739 12.7681 16.485L12.7696 16.4853C12.7877 16.4898 12.8683 16.5094 12.9681 16.4943C13.0328 16.4846 13.1 16.4616 13.1633 16.4215C13.192 16.4033 13.2139 16.3853 13.2286 16.3722C13.2358 16.3658 13.2414 16.3605 13.2449 16.3571L13.2489 16.3531L13.25 16.3521L13.2503 16.3517C13.3656 16.2364 13.4174 16.0721 13.3891 15.9116L13.0203 13.8167C15.0928 12.644 16.5 10.5696 16.5 8.17605ZM9 2.5C12.6463 2.5 15.5 5.10021 15.5 8.17605C15.5 10.2572 14.2116 12.1081 12.2403 13.0999C12.0431 13.1991 11.9343 13.4158 11.9726 13.6332L12.2477 15.1958C11.6891 14.9602 10.7561 14.5648 9.19507 13.9034C9.13336 13.8772 9.06702 13.8638 9 13.8638C5.35369 13.8638 2.5 11.2636 2.5 8.18772C2.5 5.11074 5.35498 2.5 9 2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default memo(IconReply)
