import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMemo } from 'react'

export const FLEA_MARKET_FILTER_SORT = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최신순',
  },
} as const

export type FleaMarketSortIdType =
  (typeof FLEA_MARKET_FILTER_SORT)[keyof typeof FLEA_MARKET_FILTER_SORT]['ID']

export interface FilterSortStateType {
  sortId: FleaMarketSortIdType
}

const filterSortState = atom<FilterSortStateType>({
  key: 'FLEA_MARKET_FILTER/SORT',
  default: {
    sortId: 'RELEVANT',
  },
})

export const useFleaMarketFilterSortState = () => {
  return useRecoilValue(filterSortState)
}

export const useFleaMarketFilterSortActions = () => {
  const setter = useSetRecoilState(filterSortState)

  return useMemo(
    () => ({
      changeSort: (range: FilterSortStateType) => setter(range),
      resetSort: () =>
        setter({
          sortId: 'RELEVANT',
        }),
    }),
    [setter]
  )
}

export const sortLabelOf = (sortId: FleaMarketSortIdType) => {
  return (
    Object.values(FLEA_MARKET_FILTER_SORT).find((sort) => sort.ID === sortId)
      ?.LABEL ?? ''
  )
}

export const isDefaultSort = (sortId: FleaMarketSortIdType) => {
  return sortId === FLEA_MARKET_FILTER_SORT.RELEVANT.ID
}
