import { vars } from '@seed-design/design-token'

const IconClock = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00001 4.95912C9.37195 4.95912 9.67348 5.26064 9.67348 5.63259V8.63951L11.8991 10.1233C12.2086 10.3296 12.2922 10.7477 12.0859 11.0572C11.8796 11.3667 11.4614 11.4503 11.1519 11.244L8.62643 9.5603C8.43907 9.43539 8.32654 9.22512 8.32654 8.99994V5.63259C8.32654 5.26064 8.62806 4.95912 9.00001 4.95912Z"
        fill={vars.$scale.color.gray600}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8.99989C1.5 4.85776 4.85786 1.49989 9 1.49989C13.1421 1.49989 16.5 4.85776 16.5 8.99989C16.5 13.142 13.1421 16.4999 9 16.4999C4.85786 16.4999 1.5 13.142 1.5 8.99989ZM9 2.72438C5.53413 2.72438 2.72449 5.53403 2.72449 8.99989C2.72449 12.4658 5.53413 15.2754 9 15.2754C12.4659 15.2754 15.2755 12.4658 15.2755 8.99989C15.2755 5.53403 12.4659 2.72438 9 2.72438Z"
        fill={vars.$scale.color.gray600}
      />
    </svg>
  )
}

export default IconClock
