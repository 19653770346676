export const SEARCH_WEBVIEW_ROUTES = {
  Home: '/',
  HomeSearch: '/home',
  Search: '/search',
  SearchDiscover: '/search/discover',
  AppLoginGuide: '/app/login-guide',
  AppError: '/app/error',
  ActionSheetPlaceAdMore: '/app/action-sheet/place-ad/more',
  ActionSheetCatalogProductAdMore: '/app/action-sheet/catalog-product-ad/more',
  ActionSheetNeighborAdMore: '/app/action-sheet/neighbor-ad/more',
  ActionSheetNeighborAdInfo: '/app/action-sheet/neighbor-ad/info',
  ActionSheetKeywordNotification: '/app/action-sheet/keyword-notification',
  RegisterUserKeywordError: '/keyword-notification/error',
  FleaMarketFilterRegion: '/search/flea-market/filter/region',
  FleaMarketFilterPrice: '/search/flea-market/filter/price',
  FleaMarketFilterCategory: '/search/flea-market/filter/category',
  FleaMarketFilterSort: '/search/flea-market/filter/sort',
  FleaMarketFilterRegionRange: '/search/flea-market/filter/region-range',
  SatisfactionSurveyForm: '/app/action-sheet/satisfaction-survey-form',
} as const
