import { memo } from 'react'

const IconHeart = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6 3.75767C4.19705 2.08078 6.8171 2.08078 8.41414 3.75767L8.99207 4.36634L9.56948 3.75822C11.1665 2.08132 13.7871 2.08078 15.3841 3.75767L15.3853 3.7589C16.9989 5.46467 16.7342 8.02748 15.4209 9.76409L15.4201 9.7651C14.0881 11.5172 12.5486 12.9304 11.3467 13.9036C10.7447 14.391 10.2249 14.7701 9.85434 15.0281C9.669 15.1572 9.52082 15.2561 9.41817 15.3232C9.36684 15.3568 9.32688 15.3824 9.29933 15.3999L9.26745 15.42L9.25873 15.4255L9.25621 15.427L9.25515 15.4277C9.25504 15.4278 9.25495 15.4278 8.99207 15.0025C8.73678 15.4324 8.73668 15.4324 8.73656 15.4323L8.73544 15.4316L8.73281 15.43L8.72369 15.4246L8.69047 15.4044C8.66179 15.3868 8.62025 15.3612 8.56699 15.3275C8.46048 15.2603 8.30703 15.1613 8.11584 15.0321C7.7336 14.7737 7.19961 14.3941 6.58772 13.9058C5.36674 12.9316 3.82199 11.5143 2.55617 9.75446L2.55555 9.75359C1.30983 8.01389 0.977841 5.46094 2.6 3.75767ZM8.99207 15.0025L8.73656 15.4323C8.89667 15.5274 9.09675 15.5256 9.25515 15.4277L8.99207 15.0025ZM8.98716 14.4094C9.06874 14.3549 9.16809 14.2874 9.28293 14.2075C9.63673 13.9611 10.1369 13.5965 10.7174 13.1264C11.8804 12.1847 13.3556 10.8282 14.6236 9.16053C15.7298 7.69741 15.8451 5.70085 14.6594 4.44669C13.4564 3.18427 11.497 3.18444 10.2943 4.44718C10.2942 4.44723 10.2943 4.44713 10.2943 4.44718L9.35466 5.43678C9.26026 5.5362 9.12917 5.5925 8.99207 5.5925C8.85497 5.5925 8.72388 5.5362 8.62948 5.43678L7.69 4.44733C7.68993 4.44725 7.69008 4.4474 7.69 4.44733C6.48703 3.18445 4.52703 3.1843 3.32414 4.44733C2.14639 5.68397 2.2943 7.67083 3.36837 9.17109C4.56248 10.831 6.03253 12.1835 7.21142 13.1242C7.79953 13.5934 8.3118 13.9575 8.67581 14.2036C8.79702 14.2855 8.9017 14.3543 8.98716 14.4094Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default memo(IconHeart)
