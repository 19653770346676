import type { FunnelFromIdTypes } from '../constants'
import { FUNNEL_FROM, QUERY_FROM } from '../constants'
import { findConstantObjectById } from '../../_app/utils/helpers'

export const findQueryFromValueById = (queryFromId?: string) => {
  if (!queryFromId) {
    return QUERY_FROM.UNKNOWN.VALUE
  }

  const queryFromConstant = findConstantObjectById(QUERY_FROM, queryFromId)

  return queryFromConstant ? queryFromConstant.VALUE : QUERY_FROM.UNKNOWN.VALUE
}

export const findFunnelFromValueById = (funnelFromId?: string) => {
  if (!funnelFromId) {
    return FUNNEL_FROM.UNKNOWN.VALUE
  }

  const funnelFromConstant = findConstantObjectById(FUNNEL_FROM, funnelFromId)

  return funnelFromConstant
    ? funnelFromConstant.VALUE
    : FUNNEL_FROM.UNKNOWN.VALUE
}

export function injectFunnelFromSchemeUrl(
  targetUri: string,
  funnelFromId: FunnelFromIdTypes
) {
  if (!targetUri) {
    return targetUri
  }

  const originalTargetUrl = new URL(targetUri)

  // 로컬 웹뷰
  const pathParams = originalTargetUrl.searchParams.get('path')

  if (pathParams) {
    const pathUrl = new URL(pathParams, 'file://') // file:// dummy
    pathUrl.searchParams.set(
      'funnelFrom',
      findFunnelFromValueById(funnelFromId)
    )
    originalTargetUrl.searchParams.set(
      'path',
      `${pathUrl.pathname}${pathUrl.search}`
    )

    return originalTargetUrl.toString()
  }

  // 리모트 웹뷰
  const remoteParams = originalTargetUrl.searchParams.get('remote')

  if (remoteParams) {
    const params = new URL(remoteParams)
    params.searchParams.set('funnelFrom', findFunnelFromValueById(funnelFromId))
    originalTargetUrl.searchParams.set('remote', params.toString())

    return originalTargetUrl.toString()
  }

  // 그 외(앱 스킴)는 아무런 처리 하지 않음
  return targetUri
}

/**
 * funnelFromId 값 보정
 * @param funnelFromId
 */
export function correctFunnelFromId(funnelFromId?: FunnelFromIdTypes | null) {
  if (!funnelFromId) {
    return FUNNEL_FROM.UNKNOWN.ID
  }

  if (funnelFromId === FUNNEL_FROM.STORY.ID) {
    return FUNNEL_FROM.COMMUNITY.ID
  }

  return funnelFromId
}
