import type { FC, HTMLAttributes } from 'react'
import { Bone } from './Skeleton.css'

interface IPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  width: string
  height: string
  margin?: {
    left?: string
    right?: string
    top?: string
    bottom?: string
  }
  border?: {
    radius?: string
  }
  display?: string
  flex?: string
  theme?: any
}

const Skeleton: FC<IPlaceholderProps> = (props) => {
  return (
    <div
      {...props}
      className={Bone}
      style={{
        width: props.width,
        height: props.height,
        borderRadius: props.border?.radius || '.25rem',
        display: props.display || 'block',
        marginBottom: props.margin?.bottom || 0,
        marginTop: props.margin?.top || 0,
        marginLeft: props.margin?.left || 0,
        marginRight: props.margin?.right || 0,
        flex: props.flex || 'none',
        verticalAlign: props.display === 'inline-block' ? 'middle' : 'initial',
      }}
    >
      {props.children}
    </div>
  )
}

export default Skeleton
