export const CATALOG_PRODUCT_AD_VIEW_TYPE = {
  LIST_VIEW: 'LIST_VIEW',
  CAROUSEL: 'CAROUSEL',
} as const

export type CatalogProductAdViewType =
  (typeof CATALOG_PRODUCT_AD_VIEW_TYPE)[keyof typeof CATALOG_PRODUCT_AD_VIEW_TYPE]

export const DSP_ADVERTISEMENT_COMPONENT_TYPE = {
  CATALOG_ADVERTISEMENT: 'CATALOG_ADVERTISEMENT',
  CAROUSEL_ADVERTISEMENT: 'CAROUSEL_ADVERTISEMENT',
  UNKNOWN: 'UNKNOWN',
} as const
