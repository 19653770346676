import 'src/packages/business/CardBizPost.css.ts.vanilla.css?source=#H4sIAAAAAAAAA71WUW+bMBB+769AlSatD0YhaZOGaC/rU9/2Pk2VMQauNRjZJmk67b/PGBwImJZ00hRFgH3+fPfdd2f7D1jE3+HtB5fqSYFi9IEXCkNBxdPTcxYEjC+831eeF4MsGT6GXsLo604PYAZpgUDRXIYeoYWiYnf158rvAwqaVgyfkAI3Uv0MvYX+4UrxeuS5kgqSIyLaF43c4U9sqzG0JZLwRjWQv7kTNK9HI0xeUsGrIg69PRZfEZKUxkgSzKgGZ1ygVOAjChaLG2PPRUwFEjiGStZIwf0JK8cihQIJSDNVTy3txAFilYVe4He2GW2sAn/Vjg2IIbwqeWF5WRpeSi5BAS9CD0eSs0rRGorRpN6uflW8bN9Gfq4C405Non3fuai+nNg2uqU/HZvn8RITUMfak+3l2aA5LhSQNiGlAE310eTDjJwbK9yZth+HTHt94+B4IOSVW36OTYb62Bp9DPC1m+m4Wm7NJmNJtzyu/ZFI1i6GkMOrAU+41BLoVtwMABr6Cd83eezNdEK7W3yp/2N5BzaxenXC+CH0MohjWuzOdCoo0wnYU7ck3cqfYm1sZOfuDKOz+OPRMyUKJaD6kV/i2WnTn1KQb9fXv841U/CCjhbmOhS7bj3d4lAMQntnmNMprPKia37BNLG5zkob/MKpcV2qdvdNI75+9Y0aWNNFfEeTQAcavYBCETc+20+mE4UIw7let+xPaDvEBZg+ornW0sTM9Cn6qlCnHMoYlBLklJ4OOj8oEhS/hJ55IMwMzh4kRMBMYzHvjM6s142rXs3pZpm6P8/TjNCD/xl6L4W2FI0vp+Z721TtZ3tXKXgCjD46i3HbL7iF41jrj42qa7388MR0UfEvt4LLgpu0tSbYXcMzCZnXgyxL77h+cueCVkRwWXcQuzQyS2YXzLB1BKupe8TEZWGY8d5V5Fy+7aHT3SXcdAzDCRmWCpEMWGwiG+z38foEhAbgCVLHkvYhmmtWozQ3iLQoxC2Pfnt9D+eRdA7Fbqh3bmknEW5XDhX2BmdmKNgE23bFDJmsXb1EVtFD4+io2M4obg8em+m/NJtIPHoMAAA=';
export var caption = 'CardBizPost_caption__jh11lob';
export var captionIcon = 'CardBizPost_captionIcon__jh11lod';
export var captions = 'CardBizPost_captions__jh11loc';
export var container = 'CardBizPost_container__jh11lo3';
export var content = 'CardBizPost_content__jh11lo7';
export var coupon = 'CardBizPost_coupon__jh11lo2';
export var image = 'CardBizPost_image__jh11lo5';
export var imageContainer = 'CardBizPost_imageContainer__jh11lo4';
export var main = 'CardBizPost_main__jh11lo6';
export var profileImage = 'CardBizPost_profileImage__jh11loa';
export var profileImageContainer = 'CardBizPost_profileImageContainer__jh11lo9';
export var regular = 'CardBizPost_regular__jh11lo1';
export var subContentContainer = 'CardBizPost_subContentContainer__jh11loe';
export var title = 'CardBizPost_title__jh11lo8';
export var titleContainer = 'CardBizPost_titleContainer__jh11lo0';