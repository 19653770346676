import { memo } from 'react'

interface IconHeartProps {
  className?: string
}
const IconHeart = (props: IconHeartProps) => {
  return (
    <svg
      className={props.className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.60013 1.75767C3.19717 0.0807761 5.81722 0.0807763 7.41426 1.75767L7.99219 2.36634L8.5696 1.75822C10.1666 0.0813257 12.7872 0.0807769 14.3843 1.75767L14.3854 1.7589C15.999 3.46467 15.7343 6.02748 14.421 7.76409L14.4202 7.7651C13.0883 9.51716 11.5487 10.9304 10.3468 11.9036C9.74487 12.391 9.22503 12.7701 8.85447 13.0281C8.66912 13.1572 8.52094 13.2561 8.41829 13.3232C8.36696 13.3568 8.327 13.3824 8.29945 13.3999L8.26757 13.42L8.25885 13.4255L8.25634 13.427L8.25527 13.4277C8.25516 13.4278 8.25507 13.4278 7.99219 13.0025C7.7369 13.4324 7.7368 13.4324 7.73669 13.4323L7.73556 13.4316L7.73293 13.43L7.72381 13.4246L7.69059 13.4044C7.66191 13.3868 7.62037 13.3612 7.56711 13.3275C7.4606 13.2603 7.30715 13.1613 7.11596 13.0321C6.73372 12.7737 6.19973 12.3941 5.58784 11.9058C4.36687 10.9316 2.82212 9.51427 1.55629 7.75446L1.55567 7.75359C0.309951 6.01389 -0.0220372 3.46094 1.60013 1.75767ZM7.99219 13.0025L7.73669 13.4323C7.89679 13.5274 8.09688 13.5256 8.25527 13.4277L7.99219 13.0025ZM7.98728 12.4094C8.06886 12.3549 8.16821 12.2874 8.28305 12.2075C8.63686 11.9611 9.13702 11.5965 9.71755 11.1264C10.8805 10.1847 12.3558 8.82819 13.6237 7.16054C14.7299 5.69741 14.8453 3.70085 13.6595 2.44669C12.4566 1.18427 10.4972 1.18444 9.2944 2.44718C9.29436 2.44723 9.29445 2.44714 9.2944 2.44718L8.35479 3.43678C8.26038 3.5362 8.1293 3.5925 7.99219 3.5925C7.85509 3.5925 7.72401 3.5362 7.6296 3.43678L6.69013 2.44733C6.69005 2.44725 6.6902 2.4474 6.69013 2.44733C5.48715 1.18445 3.52715 1.1843 2.32426 2.44733C1.14651 3.68397 1.29442 5.67083 2.36849 7.17109C3.5626 8.83103 5.03265 10.1835 6.21154 11.1242C6.79965 11.5934 7.31192 11.9575 7.67593 12.2036C7.79714 12.2855 7.90182 12.3543 7.98728 12.4094Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default memo(IconHeart)
