import type { MouseEvent } from 'react'
import { Fragment } from 'react'
import {
  AutoCompletionCategory,
  AutoCompletionLabel,
  Container,
  Highlight,
  LabelContainer,
  SearchIcon,
} from './AutoCompleteKeyword.css'

export interface AutoCompleteKeywordType {
  readonly name: string
  readonly targetUri?: string
}

interface AutoCompleteKeywordProps {
  keyword: AutoCompleteKeywordType
  query: string
  index: number
  isCategory?: boolean
  onClick: (
    e: MouseEvent<HTMLDivElement>,
    info: {
      keyword: AutoCompleteKeywordType
      query: string
      index: number
    }
  ) => void
}

const AutoCompleteKeyword = (props: AutoCompleteKeywordProps) => {
  const splitted = props.keyword.name.split(props.query)

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    props.onClick(e, {
      keyword: props.keyword,
      query: props.query,
      index: props.index,
    })
  }

  const a11yLabel = `${props.isCategory ? '카테고리 ' : ''}${
    props.keyword.name
  }`

  return (
    <div
      className={Container}
      role="button"
      aria-label={a11yLabel}
      onClick={onClick}
    >
      <IcSearchOutlineS />
      <div className={LabelContainer}>
        <label className={AutoCompletionLabel}>
          {splitted.map((word, wordIndex) => {
            const isLast = wordIndex === splitted.length - 1

            return (
              <Fragment key={wordIndex}>
                <>{word}</>
                {!isLast && <mark className={Highlight}>{props.query}</mark>}
              </Fragment>
            )
          })}
        </label>
        {props.isCategory ? (
          <span className={AutoCompletionCategory}>카테고리</span>
        ) : null}
      </div>
    </div>
  )
}

function IcSearchOutlineS() {
  return (
    <svg
      className={SearchIcon}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9502 2.69995C4.77456 2.69995 2.2002 5.27431 2.2002 8.44995C2.2002 11.6256 4.77456 14.2 7.9502 14.2C9.3579 14.2 10.6475 13.6941 11.647 12.8542L14.9463 16.1535C15.1415 16.3487 15.4581 16.3487 15.6534 16.1535C15.8486 15.9582 15.8486 15.6416 15.6534 15.4464L12.3541 12.1472C13.1942 11.1476 13.7002 9.85785 13.7002 8.44995C13.7002 5.27431 11.1258 2.69995 7.9502 2.69995ZM3.2002 8.44995C3.2002 5.8266 5.32684 3.69995 7.9502 3.69995C10.5735 3.69995 12.7002 5.8266 12.7002 8.44995C12.7002 11.0733 10.5735 13.2 7.9502 13.2C5.32684 13.2 3.2002 11.0733 3.2002 8.44995Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AutoCompleteKeyword
