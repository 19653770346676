interface IconReviewProps {
  className?: string
}
const IconReview = (props: IconReviewProps) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={props.className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8125 0.749878C9.9214 0.749878 12.375 3.01803 12.375 5.73778C12.375 7.29705 11.5755 8.69972 10.3035 9.62071C10.2061 9.69123 10.1484 9.80419 10.1484 9.92445V11.5794C10.0229 11.5028 9.8849 11.4188 9.7433 11.3332C9.24451 11.0317 8.6632 10.6865 8.45226 10.5904C8.377 10.5562 8.29251 10.5477 8.21196 10.5665C7.7653 10.6703 7.2964 10.7257 6.8125 10.7257C3.7036 10.7257 1.25 8.45753 1.25 5.73778C1.25 3.01803 3.7036 0.749878 6.8125 0.749878ZM10.326 12.5687C10.4416 12.6403 10.587 12.6437 10.7059 12.5775C10.8248 12.5113 10.8984 12.3859 10.8984 12.2499V10.1122C12.2531 9.0658 13.125 7.49936 13.125 5.73778C13.125 2.53383 10.262 -0.00012207 6.8125 -0.00012207C3.36302 -0.00012207 0.5 2.53383 0.5 5.73778C0.5 8.94173 3.36302 11.4757 6.8125 11.4757C7.30571 11.4757 7.78619 11.4242 8.24762 11.3268C8.47458 11.4482 8.91855 11.7111 9.35533 11.9751C9.60948 12.1287 9.85221 12.2771 10.0315 12.3872C10.1211 12.4423 10.1948 12.4877 10.2461 12.5193L10.326 12.5687Z"
        fill="#868B94"
      />
      <path
        className={props.className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01562 4.74988C4.01562 4.54277 4.18351 4.37488 4.39062 4.37488H9.31249C9.5196 4.37488 9.68749 4.54277 9.68749 4.74988C9.68749 4.95698 9.5196 5.12488 9.31249 5.12488H4.39062C4.18351 5.12488 4.01562 4.95698 4.01562 4.74988Z"
        fill="#868B94"
      />
      <path
        className={props.className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01562 6.93738C4.01562 6.73027 4.18351 6.56238 4.39062 6.56238H9.31249C9.5196 6.56238 9.68749 6.73027 9.68749 6.93738C9.68749 7.14448 9.5196 7.31238 9.31249 7.31238H4.39062C4.18351 7.31238 4.01562 7.14448 4.01562 6.93738Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default IconReview
