import { vars } from '@seed-design/design-token'

const IconClose = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon">
        <path
          id="vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.51779 2.51776C2.76367 2.27187 3.16233 2.27187 3.40822 2.51776L8.00004 7.10958L12.5919 2.51776C12.8377 2.27187 13.2364 2.27187 13.4823 2.51776C13.7282 2.76364 13.7282 3.1623 13.4823 3.40819L8.89047 8.00001L13.4823 12.5918C13.7282 12.8377 13.7282 13.2364 13.4823 13.4823C13.2364 13.7281 12.8377 13.7281 12.5919 13.4823L8.00004 8.89044L3.40822 13.4823C3.16233 13.7281 2.76367 13.7281 2.51779 13.4823C2.2719 13.2364 2.2719 12.8377 2.51779 12.5918L7.10961 8.00001L2.51779 3.40819C2.2719 3.1623 2.2719 2.76364 2.51779 2.51776Z"
          fill={vars.$scale.color.gray600}
        />
      </g>
    </svg>
  )
}
export default IconClose
