const IconReply = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0002 2.82505C12.7309 2.82505 15.6752 5.54684 15.6752 8.81053C15.6752 10.6817 14.7158 12.3649 13.1894 13.47C13.0725 13.5547 13.0033 13.6902 13.0033 13.8345V15.8205C12.8527 15.7285 12.6871 15.6278 12.5172 15.5251C11.9186 15.1633 11.221 14.749 10.9679 14.6337C10.8776 14.5926 10.7762 14.5825 10.6795 14.605C10.1435 14.7296 9.58087 14.796 9.0002 14.796C5.26951 14.796 2.3252 12.0742 2.3252 8.81053C2.3252 5.54684 5.26951 2.82505 9.0002 2.82505ZM13.2164 17.0076C13.3552 17.0936 13.5296 17.0976 13.6723 17.0182C13.8149 16.9388 13.9033 16.7883 13.9033 16.625V14.0598C15.5289 12.8042 16.5752 10.9244 16.5752 8.81053C16.5752 4.96579 13.1396 1.92505 9.0002 1.92505C4.86082 1.92505 1.42519 4.96579 1.42519 8.81053C1.42519 12.6553 4.86082 15.696 9.0002 15.696C9.59205 15.696 10.1686 15.6343 10.7223 15.5173C10.9947 15.663 11.5275 15.9785 12.0516 16.2953C12.3566 16.4796 12.6478 16.6578 12.863 16.7899C12.9705 16.8559 13.059 16.9104 13.1205 16.9484L13.2164 17.0076Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default IconReply
