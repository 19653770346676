import 'src/packages/car/CardCar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTW/bMAy991cYAwZsBxl2uvTDxU69bOceh6GgLcZWK1uGRCfNhv33SYodf6ZIiiJII+U9ko+PjMNH0Ny+njNVEYgK9fNzTDdRs42Cv1dBwIWpJeyTYCPx7cEeZEoqnQRb0F8YM4icmQwkMn/Ocg17dh9FXx+u/l2FHbcoIcfHaYDYB3C8SRDZP2hIuQg7walIgpvwdq2xdCcFiryg0VGqNEebR1y/BUZJwd/NCGRdAFu7tCwUstdcq6bibKEYLKEikbXoGmrUrEdMCYz4g4nVZIt6clMrI0ioKgnW0Wf3cvcl6FxUTB/KidtaHHoj1S4JCsE5Vu6sh2uUQGKLfdVMAxeNsaqF160gZ4g9/kJ3vvJNOEtylb5gRmwjaFjxuRkdA/4yOvv+6dPvsb0qVeEI5PyIFZkOd71sR/fOuNA2M6+WbVtTVg9HY8WnxSxtJ9rCo1HoAoH3yn07L7BWO3f80hgSmz1r008CU0OGLEXaoevsIAwJkkdZ1odhsKjWU0uepH2trJ/rYs8kpChXTGPeSNDsCPQG9Z92bQc/QHSAeippTcSKD1ENoAcqJLJWcYKIKr+YbYT2hMee2ITTV0EsVb413UefQCahrJNg5S62wohUSEEW4/+XOPy+hTOlhW+btbfNB6S7J3wj1g8pSilqI8yp0d3ZkWCpRnhNAv9m149cmP6wGyzPD1Lk1kYSNzRySar4vjPJzaUz0EYkVfvRjFez2cxAP9WYzfbF7UWu98kzQVjaHZBZAd1yGBYBfGEn3V0UY1LLfM34IB33veceKx6F8/rHoESCIZYVQvIl/AgJziASnwioMbPaYIg/nfNGKepB6UWCLG+1xVYs7CVPihWfSjv5FY4j98s1XOaW4bEAIjuFXd7ZYXkJKd9/MLiZPRhYsh9oleyY+EeZMqidFh0Pep4znlVuo6hfmYfNG4V33aAsNOOEvlOnXfcUoxU603Oa+CUWbLE/sx6/WbbQrP195icK6vof9oUcaxiczSYsvm9vzhC/7eJ/4DWj9oQKAAA=';
export var articleStatusContainer = 'CardCar_articleStatusContainer__1t60uva';
export var badge = 'CardCar_badge__1t60uv9';
export var badgeContainer = 'CardCar_badgeContainer__1t60uv8';
export var body = 'CardCar_body__1t60uv6';
export var caption = 'CardCar_caption__1t60uve';
export var captionIcon = 'CardCar_captionIcon__1t60uvf';
export var carSpecContainer = 'CardCar_carSpecContainer__1t60uv7';
export var container = 'CardCar_container__1t60uv0';
export var contents = 'CardCar_contents__1t60uv3';
export var footer = 'CardCar_footer__1t60uvb';
export var header = 'CardCar_header__1t60uv4';
export var iconChatting = 'CardCar_iconChatting__1t60uvc';
export var iconHeart = 'CardCar_iconHeart__1t60uvd';
export var image = 'CardCar_image__1t60uv2';
export var imageContainer = 'CardCar_imageContainer__1t60uv1';
export var title = 'CardCar_title__1t60uv5';