import { STAGE } from '../../_app/constants/common'
import { DSP_ADVERTISEMENT_COMPONENT_TYPE } from '../../advertisement/constants'

export const FLEA_MARKET_COMPONENT_TYPE = {
  FLEA_MARKET: 'FLEA_MARKET',
  CATALOG_ADVERTISEMENT: DSP_ADVERTISEMENT_COMPONENT_TYPE.CATALOG_ADVERTISEMENT,
  CAROUSEL_ADVERTISEMENT:
    DSP_ADVERTISEMENT_COMPONENT_TYPE.CAROUSEL_ADVERTISEMENT,
  RELATED_KEYWORD: 'RELATED_KEYWORD',
  NEIGHBOR_AD: 'NEIGHBOR_AD',
  UNKNOWN: 'UNKNOWN',
} as const

export const FLEA_MARKET_BUYING_CATEGORY = STAGE === 'production' ? '32' : '128'
