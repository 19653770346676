interface IconHeartProps {
  className?: string
}
const IconHeart = (props: IconHeartProps) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={props.className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74838 1.50462C3.19495 -0.00154018 5.56698 -0.00154018 7.01355 1.50462L7.01408 1.50517L7.4921 2.00439L7.97064 1.50462C9.41721 -0.00154018 11.7892 -0.00154018 13.2358 1.50462L13.237 1.50584C14.7027 3.04234 14.4575 5.34562 13.2728 6.89902L13.272 6.90003C12.0793 8.45576 10.7013 9.70995 9.62629 10.5731C9.08773 11.0055 8.6226 11.3419 8.29089 11.5709C8.12498 11.6855 7.99226 11.7733 7.90022 11.833C7.8542 11.8628 7.81833 11.8856 7.79355 11.9012L7.7648 11.9192L7.75688 11.9241L7.75457 11.9256L7.75383 11.926C7.75383 11.926 7.75337 11.9263 7.4921 11.5C7.23839 11.9308 7.23818 11.9307 7.23818 11.9307L7.23712 11.9301L7.2347 11.9287L7.22642 11.9237L7.19645 11.9057C7.17065 11.89 7.13335 11.8672 7.08559 11.8373C6.99008 11.7775 6.85262 11.6895 6.68144 11.5748C6.33924 11.3455 5.86137 11.0085 5.31383 10.5753C4.22161 9.71113 2.83839 8.45289 1.70426 6.8894L1.70363 6.88853C0.581347 5.33435 0.273934 3.0398 1.74838 1.50462ZM7.4921 11.5L7.23818 11.9307C7.39748 12.0245 7.59621 12.0226 7.75383 11.926L7.4921 11.5ZM7.48723 10.9078C7.55421 10.8632 7.63316 10.8098 7.72272 10.748C8.03767 10.5305 8.48312 10.2085 9.0002 9.79334C10.0364 8.96138 11.3497 7.76367 12.4779 6.29218C13.4531 5.01314 13.5471 3.28045 12.514 2.19674C11.461 1.10096 9.74493 1.10109 8.69206 2.19712L7.85324 3.07315C7.75892 3.17165 7.62847 3.22735 7.4921 3.22735C7.35572 3.22735 7.22527 3.17165 7.13096 3.07315L6.29232 2.19732C5.23927 1.10104 3.5226 1.10094 2.46961 2.19732C1.44454 3.26461 1.5655 4.98877 2.51406 6.30269C3.57596 7.76648 4.88413 8.96017 5.93432 9.79111C6.45805 10.2055 6.91419 10.527 7.23818 10.7441C7.33318 10.8078 7.41674 10.8624 7.48723 10.9078Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default IconHeart
