const IconThumbUp = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9143 5.3992C12.0803 4.93785 12.039 4.43121 11.9097 3.98254C11.779 3.52932 11.5466 3.09219 11.2724 2.74247C11.0069 2.40374 10.6601 2.09392 10.276 1.97556C10.0746 1.91347 9.84372 1.89911 9.616 1.98713C9.38691 2.07567 9.21371 2.24745 9.09194 2.46059C9.0863 2.47046 9.08104 2.48053 9.07617 2.49079C7.73721 5.31044 6.40807 7.08069 5.82064 7.72604H2.3626C2.11407 7.72604 1.9126 7.92752 1.9126 8.17604V16.2313C1.9126 16.4799 2.11407 16.6813 2.3626 16.6813H11.856C12.9167 16.6813 13.5579 16.4436 14.0003 15.9999C14.9814 15.016 15.6279 13.401 16.0605 11.9743C16.2996 11.1855 16.5557 10.1731 16.5379 9.27325C16.5289 8.82056 16.4505 8.36284 16.2382 7.96846C16.0192 7.56151 15.6691 7.24483 15.1705 7.07643C15.0533 7.03684 14.939 7.01056 14.833 6.99728C14.8145 6.99495 14.7958 6.99379 14.7771 6.99379H11.1227L11.8936 5.4477C11.9015 5.43195 11.9084 5.41576 11.9143 5.3992ZM6.44999 8.37122C7.10462 7.6641 8.49689 5.80436 9.88063 2.8948C9.89933 2.86422 9.91489 2.84705 9.9246 2.83807C9.93462 2.82881 9.94051 2.82659 9.94051 2.82659C9.94051 2.82659 9.94448 2.82503 9.9536 2.82505C9.96383 2.82508 9.98258 2.82689 10.011 2.83564C10.142 2.87604 10.3484 3.02255 10.5641 3.29771C10.7712 3.56188 10.9481 3.89617 11.0449 4.2319C11.1398 4.56097 11.1471 4.85221 11.0751 5.07237L9.99278 7.24299C9.92322 7.38248 9.93081 7.54803 10.0128 7.68058C10.0949 7.81312 10.2396 7.89379 10.3955 7.89379H14.745C14.7829 7.90008 14.8296 7.91122 14.8825 7.9291C15.1595 8.02266 15.332 8.18373 15.4457 8.39501C15.5662 8.61886 15.6307 8.91986 15.6381 9.29106C15.6529 10.039 15.435 10.9351 15.1992 11.7131C14.7704 13.1274 14.1744 14.5507 13.363 15.3644C13.1513 15.5767 12.7824 15.7813 11.856 15.7813H6.44999V8.37122ZM5.54999 8.62604H2.8126V15.7813H5.54999V8.62604Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default IconThumbUp
