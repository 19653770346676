export const COLLECTION_TYPE = {
  AD: 'AD',
  FLEA_MARKET: 'FLEA_MARKET',
  COMMUNITY: 'COMMUNITY',
  BUSINESS: 'BUSINESS',
  PLACE: 'PLACE',
  SHORTCUT: 'SHORTCUT',
  JOB: 'JOB',
  COMMERCE: 'COMMERCE',
  CAR: 'CAR',
  REALTY: 'REALTY',
  KARROT_GROUP: 'KARROT_GROUP',
  /**
   * TODO: 통합탭 실험 종료 후 삭제
   * 통합탭 실험군 3 용 임시 컬렉션
   */
  DSP_AD: 'DSP_AD',
  UNKNOWN: 'UNKNOWN',
} as const

export type CollectionType =
  (typeof COLLECTION_TYPE)[keyof typeof COLLECTION_TYPE]

export const DEFAULT_COLLECTION_RANK = [
  COLLECTION_TYPE.SHORTCUT,
  COLLECTION_TYPE.AD,
  COLLECTION_TYPE.FLEA_MARKET,
  /**
   * TODO: 통합탭 실험 종료 후 삭제
   * 통합탭 실험군 3 용 임시 컬렉션
   */
  COLLECTION_TYPE.DSP_AD,
  COLLECTION_TYPE.COMMUNITY,
  COLLECTION_TYPE.PLACE,
  COLLECTION_TYPE.BUSINESS,
  COLLECTION_TYPE.JOB,
  COLLECTION_TYPE.CAR,
  COLLECTION_TYPE.KARROT_GROUP,
  COLLECTION_TYPE.COMMERCE,
  COLLECTION_TYPE.REALTY,
]
