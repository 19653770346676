import 'src/packages/community/CardCommunity.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+1YXW+bMBR9769AlSatD44g/U61p2gPfa027WGaKoMdcGNsZJukbNp/37UJAQJ0IcmkPVRVS+qPc6/vuT73kskcKzKXaZoLZornFDMxl8LAg6rnZ5bpK+L73q8zzyNMZxwXM2/B6esDDNgnIkzRyDApZl4keZ6Kh7PfZ5N+UCpMBzsYh+15KVYxE0ixODEzz5/cTK8VTd+2+o2Z5DHFMe2Yn+5nXsl1w7aRmbU8YFfnYcfM5YGn3Fi6ux2wFcGnvqheOXsLGEaa/aQzb4XVR4Q0pQT+pFgYFiFTZDJWOEsKFEpSoHSKFI1zjhXa7rx4qHDWtIz4IUjlXofFwU2UHIbV2FtiUWOoQjrDERPxeLjWdocIBEjVBoowp8iNI8Ap0K3vu6UZJsRZ9ScbdjwvlIoAosKE5drOXNZTOFrGSuaCoB4jlbelnQxngBKVuasvengPMYFkxobGUhUV6dcjSec4pPz6BJz3AR1I+Q7UkYzvonUJ71xJzFksEDM0BQIjiD9Vdvgl14YtioqU5lQjEQKnRW3ed1MimNZTrzbebu9mGQzZmXUCDjg/gUUh13Cav+fQbqIGm0QdkdNtbcW5kT25Z5jhXSG9OUhxgpMpTnBCxQlOqzjBkYpz79jp8ECojhTLbOXosHHr2GgXkW3evZeFf1YWOiSx3q7jrrwsIDlADPyUNw3uPSMmmXlXk4qqKiL1yPg6Uu+42L9GlekRyVWpcY2ZTGpWdivX/gf76zRwO6gox4atqB21uxdcrmdewgiholdwB1qtjuC6dVSQhk5xuhiWqaHI9y2rZu8dL3uwIMMX8BctmGkGaSiy/b5tjX7XKvp0fv6j3SMKKejwsXJhqv3Y7asZwKGGNtI4BsqrX14KGyt/bArZs4E7QFC60/W0MqeKUFCrzL6VdVv5jJFpWXp6k/O9rxnT1/QQa3BN6+Yf12f0yZZl2WYT5o9iITu3KDz6rWkgHTqO5JqqJ4odoO74QUb5cbSkNJ3p+EIPicn2LXboJfZtkzOOtUFRwjhpFvwKtU8+oh6cRVP2gkaHWl/rYB8HvyR5Gn7NWuoUj+wLI+w6mlM0hv1QB17gDtiRV7iLd9TbaCt9/f3oeqIZL1pkJe9k/U9kWa/nHKeZ/f7qM+dAEtMVV6ytNxCqcMlM9Qa5YpqFjAPKrPzMXT/QWIWkYk4FocLDcTF3/QJ9Nahu2+jG5lAzt4bajUJF8RLeYO0DYc73Psh29DmoDvXiDlV56ViL7AqQoANgpxXschB2CrB/AKF6DZgDFQAA';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var additionalInfoContainer = 'CardCommunity_additionalInfoContainer__ips4d0b';
export var badgeCategory = 'CardCommunity_badgeCategory__ips4d05';
export var commentContainer = 'CardCommunity_commentContainer__ips4d04';
export var descriptionContainer = 'CardCommunity_descriptionContainer__ips4d07';
export var iconContainer = 'CardCommunity_iconContainer__ips4d0f';
export var image = 'CardCommunity_image__ips4d09';
export var imageContainer = 'CardCommunity_imageContainer__ips4d08';
export var imageCount = 'CardCommunity_imageCount__ips4d0a';
export var lineClampWithEllipsis = _7a468({defaultClassName:'CardCommunity_lineClampWithEllipsis__ips4d0i',variantClassNames:{lineClamp:{'1':'CardCommunity_lineClampWithEllipsis_lineClamp_1__ips4d0j','2':'CardCommunity_lineClampWithEllipsis_lineClamp_2__ips4d0k'}},defaultVariants:{lineClamp:2},compoundVariants:[]});
export var mainContainer = 'CardCommunity_mainContainer__ips4d00';
export var mainContentContainer = 'CardCommunity_mainContentContainer__ips4d01';
export var mainContentWithImageContainer = 'CardCommunity_mainContentWithImageContainer__ips4d02';
export var subContainer = 'CardCommunity_subContainer__ips4d03';
export var tagGroupContainer = 'CardCommunity_tagGroupContainer__ips4d0c';
export var titleContainer = 'CardCommunity_titleContainer__ips4d06';
export var userReactionContainer = 'CardCommunity_userReactionContainer__ips4d0e';
export var userReactionReplyCount = 'CardCommunity_userReactionReplyCount__ips4d0h';
export var userReactionThumbUpCount = 'CardCommunity_userReactionThumbUpCount__ips4d0g';
export var userReactionsContainer = 'CardCommunity_userReactionsContainer__ips4d0d';