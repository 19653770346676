import { memo } from 'react'
interface IconBidsProps {
  className?: string
}
const IconBids = (props: IconBidsProps) => {
  return (
    <svg
      className={props.className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 5.00003C1.5 3.89546 2.39542 3.00004 3.49999 3.00003L9.10396 3L14.5 3.00003C15.6046 3.00004 16.5 3.89547 16.5 5.00003L16.5 13C16.5 14.1046 15.6046 15 14.5 15L3.5 15C2.39543 15 1.5 14.1046 1.5 13L1.5 5.00003Z"
        stroke="#868B94"
        strokeLinejoin="round"
      />
      <path
        d="M3.25684 6.19263L8.40008 10.0501C8.75564 10.3167 9.24453 10.3167 9.60008 10.0501L14.7433 6.19263"
        stroke="#868B94"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default memo(IconBids)
