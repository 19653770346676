import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMemo } from 'react'

export const PLACE_FILTER_SORTS = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  DISTANCE: {
    ID: 'DISTANCE',
    LABEL: '가까운순',
  },
} as const

export type PlaceSortIdType =
  (typeof PLACE_FILTER_SORTS)[keyof typeof PLACE_FILTER_SORTS]['ID']

interface StateType {
  sortId: PlaceSortIdType
}

const state = atom<StateType>({
  key: 'PLACE_FILTER/SORT',
  default: {
    sortId: PLACE_FILTER_SORTS.RELEVANT.ID,
  },
})

export const usePlaceFilterSortState = () => {
  return useRecoilValue(state)
}

export const usePlaceFilterSortActions = () => {
  const setter = useSetRecoilState(state)

  return useMemo(
    () => ({
      changeSort: (range: StateType) => setter(range),
      resetSort: () =>
        setter({
          sortId: PLACE_FILTER_SORTS.RELEVANT.ID,
        }),
    }),
    [setter]
  )
}
