import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMemo } from 'react'

export const COMMUNITY_FILTER_SORTS = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최신순',
  },
} as const

export type CommunitySortIdType =
  (typeof COMMUNITY_FILTER_SORTS)[keyof typeof COMMUNITY_FILTER_SORTS]['ID']

interface StateType {
  sortId: CommunitySortIdType
}

const state = atom<StateType>({
  key: 'COMMUNITY_FILTER/SORT',
  default: {
    sortId: COMMUNITY_FILTER_SORTS.RELEVANT.ID,
  },
})

export const useCommunityFilterSortState = () => {
  return useRecoilValue(state)
}

export const useCommunityFilterSortActions = () => {
  const setter = useSetRecoilState(state)

  return useMemo(
    () => ({
      changeSort: (range: StateType) => setter(range),
      resetSort: () =>
        setter({
          sortId: COMMUNITY_FILTER_SORTS.RELEVANT.ID,
        }),
    }),
    [setter]
  )
}
