import { useRef, useState } from 'react'
import useTouchDraggable from '../../hooks/useTouchDraggable'
import useVisualiseLogging from './hooks/useVisualiseLogging'

const VisualiseToggleButton = () => {
  const buttonRef = useRef(null)
  const { position, onTouchStart, onTouchMove } = useTouchDraggable({
    elRef: buttonRef,
    // eslint-disable-next-line no-restricted-globals
    initialPosition: { top: innerHeight - 50, left: innerWidth - 200 },
  })
  const [isVisualiseEnabled, setIsVisualiseEnabled] = useState(false)
  useVisualiseLogging({ isVisualiseEnabled })

  return (
    <div
      ref={buttonRef}
      onClick={() => setIsVisualiseEnabled(!isVisualiseEnabled)}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        backgroundColor: 'gray',
        opacity: isVisualiseEnabled ? 0.5 : 1,
        width: '150px',
        borderRadius: '4px',
        padding: '4px',
        fontSize: '12px',
        color: 'white',
        textAlign: 'center',
        zIndex: '99999',
      }}
    >
      {isVisualiseEnabled
        ? 'Impression 시각화 비활성화'
        : 'Impression 시각화 활성화'}
    </div>
  )
}

export default VisualiseToggleButton
