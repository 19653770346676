interface DocumentCommonType {
  docToken?: string
}

export const convertCollectionToConnection = <T extends DocumentCommonType>(
  collection?: Array<T>
) => {
  return {
    edges:
      collection
        ?.filter((doc) => doc)
        .map((doc) => ({
          node: doc,
          cursor: doc.docToken ?? '',
        })) ?? [],
  }
}

interface DocumentCommonMetaType {
  meta?: {
    index?: number
    cursor?: string
  }
}

export const convertCollectionMetaToConnection = <
  T extends DocumentCommonMetaType
>(
  collection?: Array<T>
) => {
  return {
    edges:
      collection
        ?.filter((doc) => doc)
        .map((doc) => ({
          node: doc,
          cursor: doc.meta?.cursor ?? '',
        })) ?? [],
  }
}
