import { memo } from 'react'

interface IconChattingProps {
  className?: string
}
const IconChatting = (props: IconChattingProps) => {
  return (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.500001C3.50264 0.5 0.5 2.84547 0.5 5.944C0.499999 7.83832 1.6281 9.6636 3.35683 10.6597C2.9407 11.5811 2.56908 12.1314 2.35426 12.4095C2.18201 12.6325 2.18569 12.9082 2.28402 13.1102C2.38491 13.3174 2.61796 13.505 2.92888 13.4748C3.4866 13.4207 4.59815 13.2425 5.69433 12.6358C6.60069 13.0834 7.64598 13.3506 8.7593 13.3843C10.1668 15.0356 12.2371 15.3939 13.0711 15.4748C13.382 15.505 13.6151 15.3174 13.716 15.1102C13.8143 14.9082 13.818 14.6324 13.6457 14.4095C13.4309 14.1314 13.0593 13.5811 12.6432 12.6597C14.3719 11.6636 15.5 9.83831 15.5 7.94399C15.5 6.16435 14.3802 4.63944 12.9164 3.67318C11.8773 1.76896 9.57246 0.500001 7 0.500001ZM13.4755 5.46176C13.4917 5.62027 13.5 5.7811 13.5 5.944C13.5 8.9712 10.6333 11.28 7.24074 11.3843C7.05709 11.5998 6.86213 11.7933 6.66011 11.967C7.3421 12.2265 8.10278 12.3768 8.90851 12.3874C9.12263 12.3902 9.31996 12.4901 9.45003 12.651C10.3046 13.7078 11.5177 14.1631 12.4031 14.3578C12.1577 13.9549 11.8719 13.4161 11.5764 12.715C11.4516 12.419 11.5825 12.0812 11.8652 11.942C13.4728 11.1503 14.5 9.54298 14.5 7.94399C14.5 7.04462 14.1155 6.18508 13.4755 5.46176ZM1.5 5.944C1.5 3.57053 3.86463 1.5 7 1.5C10.1354 1.5 12.5 3.57053 12.5 5.944C12.5 8.30587 10.1592 10.3673 7.04597 10.3878C6.86149 10.3891 6.68959 10.475 6.57748 10.6166C5.72147 11.6979 4.49191 12.161 3.59686 12.3578C3.84229 11.9549 4.12813 11.4161 4.42364 10.715C4.54838 10.419 4.41751 10.0812 4.13483 9.94201C2.52721 9.15032 1.5 7.54299 1.5 5.944Z"
        fill="#868B94"
      />
    </svg>
  )
}
export default memo(IconChatting)
