import { useMemo } from 'react'
import { Placeholder } from '../skeleton'
import { container, body, header, removeAll, title } from './RecentSearch.css'
import { item } from './RecentSearchPlaceholder.css'

export interface RecentSearchPlaceholderProps {
  className?: string
}

const IconPlaceholder = () => {
  return (
    <Placeholder
      width="1.75rem"
      height="1.75rem"
      border={{ radius: '0.875rem' }}
      margin={{
        left: '1rem',
      }}
    />
  )
}

const KeywordPlaceholder = () => {
  return (
    <Placeholder
      width="10rem"
      height="1.75rem"
      border={{ radius: '0.875rem' }}
      margin={{
        left: '1rem',
      }}
    />
  )
}

const RecentSearchPlaceholder = (props: RecentSearchPlaceholderProps) => {
  const placeholderList = useMemo(() => new Array(5).fill(null), [])
  return (
    <article className={`${container} ${props.className ?? ''}`}>
      <header className={header}>
        <h2 className={title} a11y-label="최근 검색">
          최근 검색
        </h2>
        <span className={removeAll} role="button">
          전체 삭제
        </span>
      </header>
      <ol className={body} style={{ display: 'flex' }}>
        {placeholderList.map((_, index) => (
          <div key={index} className={item}>
            <IconPlaceholder />
            <KeywordPlaceholder />
          </div>
        ))}
      </ol>
    </article>
  )
}

export default RecentSearchPlaceholder
