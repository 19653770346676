import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import { RelayEnvironmentProvider } from 'react-relay'

import { createContext } from '../_app/graphql/context'
import { createSchema } from '../_app/graphql/schema'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import type { AppType, UserType } from '../_app/context/FoundationProvider'
import { useFoundation } from '../_app/context/FoundationProvider'
import type { SearchExperimentReferrerType } from '../referrer/types'
import type { ExperimentSegmentType } from '../experiment/stores/ExperimentStore'
import { useExperimentSegment } from '../experiment/stores/ExperimentStore'

const source = new RecordSource()
const store = new Store(source)

export function createEnvironment({
  app,
  user,
  regionId,
  experiment,
}: {
  app: AppType
  user: UserType
  regionId?: number
  experiment: SearchExperimentReferrerType
  searchExperimentSegmentArray?: ExperimentSegmentType[]
}) {
  const { query } = createSchema()

  const context = createContext({
    app,
    user,
    regionId,
    experiment,
  })

  return new Environment({
    network: Network.create((operation, variables) => {
      return query({
        query: operation.text!,
        variables,
        context,
      })
    }),
    store,
  })
}

export const RelayEnvironmentWithContextProvider = (props: {
  children: ReactNode
}) => {
  const { app, user, region } = useFoundation()
  const { experimentHeaderSegmentReferrer, searchExperimentSegmentArray } =
    useExperimentSegment()

  const memoriesEnvironment = useMemo(
    () =>
      createEnvironment({
        app: app,
        user,
        regionId: region?.id,
        experiment: experimentHeaderSegmentReferrer,
        searchExperimentSegmentArray: searchExperimentSegmentArray,
      }),
    [
      app,
      region?.id,
      user,
      experimentHeaderSegmentReferrer,
      searchExperimentSegmentArray,
    ]
  )

  return (
    <RelayEnvironmentProvider environment={memoriesEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  )
}
