import 'src/packages/navbar/SearchInputNavbar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA7VWXW+bMBR9769Amiq1D0YkQD/oUxupUl+maXvaU3RjbohVYiNjknbT/vtsg5sQQkKy7iUxBp1z7vf1fyBIunjhRaW+wmoGcjoRXAHjKKdTPs/W0Trwfl94XsrKIof3xJvn+PagL9YsVYvEGwXBpXmcAX3NpKh4SqjIhUy8FcgrQkrEVP8sgStG61ekgAIlSXEOVa6uHy7+XPhdHd8gwycN2tEz2q8HcpZxwhQuy8SjyBVKc11AmjKeJV7gBX4scdlDZ89P4s2xjPezHDaTQo6NjZmEd6Kdc70jYuTfGhVWzm2tR6MKmWqPSEhZpdUHfvjxSkngJVNM8KRD7oVBsCyHGpQAVWyF1q7TzBg3ZhzU0q/kOy7FCidM0hxfqOBOUWiluDzyR2Nn8wJZtlDbd/0WToBTzB1ktD9qhXCyYVaKvFL4MJR5O3j+eNwKy1zIZVIfc1D484rEwaX1FK1kabxaCOYSkaxx9soUUVCQhWbJDZPzvsUoQOq03XW0KIAy9X481jue2A53g2FN6MMPhoBrtypj9fSRp1Kw1LHFlkaJIvHiuh9I58h4YAQ/oCeV7g6KceHAbzbgUdwGj45gO4jbVqo1LWuuOwsp2S/UV01Yc91oyCYJwq0KfTOf2jxoqlVf2b5TFJrb2JB4XHDc1LN2d7tlbD4QlTJUm4sBVXjfVOEpiUT16ePdlyi+e35+HuSvJNHxoLgQuTbEOm+AwtgqHITujCjtl3oWUCHBJGS7gGv3nINIbVaRWaXU54FKLPXEKv8T6tlO6KvKO4uz1b/im9DOHj9u/prnYeidwrzfxY/Cuxa+ez6E/ygRHCBYQNO1dfW1G7dELUP3s6NQfc6YdZxhpI1aesOj46ZN0fEI3SWpx4ijCaOoPhziMUuPGZOdxSfdP9z+cdZsiQ37FpKmpw9uUgPt6gtVPbTO3nxOIPq0IXmAs5Mk8455Q2b8SQzn73rxoF3vkMhHKcV6e8/L2nted9dqiuEvsg/y6Y0MAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var BackArrowIcon = 'SearchInputNavbar_BackArrowIcon__nfgw4wg';
export var BackIconContainer = _7a468({defaultClassName:'SearchInputNavbar_BackIconContainer__nfgw4wd',variantClassNames:{platform:{Android:'SearchInputNavbar_BackIconContainer_platform_Android__nfgw4we',Cupertino:'SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4wf'}},defaultVariants:{},compoundVariants:[]});
export var Container = 'SearchInputNavbar_Container__nfgw4w0';
export var Input = _7a468({defaultClassName:'SearchInputNavbar_Input__nfgw4w7',variantClassNames:{platform:{Android:'SearchInputNavbar_Input_platform_Android__nfgw4w8',Cupertino:'SearchInputNavbar_Input_platform_Cupertino__nfgw4w9'}},defaultVariants:{},compoundVariants:[]});
export var InputArea = _7a468({defaultClassName:'SearchInputNavbar_InputArea__nfgw4wa',variantClassNames:{platform:{Android:'SearchInputNavbar_InputArea_platform_Android__nfgw4wb',Cupertino:'SearchInputNavbar_InputArea_platform_Cupertino__nfgw4wc'}},defaultVariants:{},compoundVariants:[]});
export var InputBox = 'SearchInputNavbar_InputBox__nfgw4w2';
export var InputCancel = _7a468({defaultClassName:'SearchInputNavbar_InputCancel__nfgw4w4',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancel_platform_Android__nfgw4w5',Cupertino:'SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w6'}},defaultVariants:{},compoundVariants:[]});
export var PageBackContainer = 'SearchInputNavbar_PageBackContainer__nfgw4w1';
export var RemoveCircleIcon = 'SearchInputNavbar_RemoveCircleIcon__nfgw4w3';