import 'src/packages/commerce/CardCommerce.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTY+bMBC9769AK620OTiCpPki6imnnlbqtapWxh6Id42NbJNsWu1/rzEQCDFpWkUJimFm3jy/N3i6w4ruZJ6DIvBaalDfARPDpNjhorro19fjOylmizD4/RAElOmC41McpBw+tnYhxypjAhlZxAEujazW3kptWHpCRAoDwtQPIxB0+/D5ML2oSOoqbZHIFSGSSxUHB6yeEdIAFGmCOSC3jjKFT2gVhpOqVGpLIM1+QRyE03U0WyjItz6cmLNMIGYg13FALCpQPfiKZXtTpZhtlpsmBWcC0B7qO1EYPv0dfcyxNojsGaeukUH26wRacvpSmjbBzEVd9LRqOzLwYZDroo+/wJQykTno1ZP2uljOm5AEk/dMyVJQ5KHUYMNIw2nzJ+E2whYp9hgtGoYLqVnVpN3exMItDbjU0hiZu56qPx8VXocjkYqCQnapunNk1Oxb9rwb60Fx3NttcrXREZJ3ZpDrPWWct33cm2KE75cDKKuOlva5o/02W0MBtiRNem0uu91qddNb8hPpjONY5JD6RZJynJ1t+KUvrDp4Ol+NyN6XqU20iIU0zz3FTnySnUbrtcs9TFUoZn+bXEsXemmY+eJpOB9strNBeyKPvAVwbrfi7IxV54xjUyGxe3kdZhkg/cD1WKDH+y02z/ZDjkWnMtu8DT7d1ug94VcDxeLElkbVwt/4py4HYwcA0gUmznUonIaz/qCgQKTCtdyEFNBRbk4cqjWVYz7S7FDrGzcKhlhtDxnshoBxzbfFaSm1n/adcJdFbltwQCIuqjlzjpgMEtTaIvJQD8renc6Ii/Cp+l6LIWoAVdEpl8c42DNKQVz6WAG3FB+agejmnsKUlbpvyntp8zzV3kwcp3cxKJM3IJUOTb/3f8J2rvpDK/L18fHnpQRrNQ0jc9tNG0j8mnWHAMoUkJo9u41lLrZntUTj5OZ2Z5r2PfPRMMPPqOn4KLoaOY1Xuk0GzlmhmXaCrV4hzmHOLkeFizFF/LeFmvPDN9KdIcBP3tWRqjsC3HmyiVbRZny+DfEu/ZYXqbySbup5I7Vz/vMPh1AtqmAKAAA=';
export var amount = 'CardCommerce_amount__wkcp257';
export var caption = 'CardCommerce_caption__wkcp251';
export var captionIcon = 'CardCommerce_captionIcon__wkcp25e';
export var container = 'CardCommerce_container__wkcp259';
export var discount = 'CardCommerce_discount__wkcp258';
export var flag = 'CardCommerce_flag__wkcp255';
export var flags = 'CardCommerce_flags__wkcp254';
export var image = 'CardCommerce_image__wkcp25b';
export var imageContainer = 'CardCommerce_imageContainer__wkcp25a';
export var infoContainer = 'CardCommerce_infoContainer__wkcp25f';
export var main = 'CardCommerce_main__wkcp25c';
export var price = 'CardCommerce_price__wkcp256';
export var soldOut = 'CardCommerce_soldOut__wkcp252';
export var soldOutOverlay = 'CardCommerce_soldOutOverlay__wkcp253';
export var title = 'CardCommerce_title__wkcp25d';
export var userReactionCaptions = 'CardCommerce_userReactionCaptions__wkcp250';